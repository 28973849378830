/*@import "~bootstrap/scss/bootstrap.scss";*/
@import "startup.scss";
@import "jetbrains-mono.scss";
@import "custom.scss";

@import "~lightbox2/dist/css/lightbox.css";
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~dropzone/dist/dropzone.css";

@import "./layout/header";
@import "./layout/footer";

body {
    /*background-color: lightblue !important;*/
}

