$body-bg: #f1fcfe;
$body-color: #303038;

$spacer: 1rem;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$primary:       $indigo !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;

$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

// Navbar
$navbar-padding-y:                  $spacer * 0.3;

body {
  padding-top: 70px;
  min-width: 300px;
  //font-family: 'JetBrains Mono', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  @media only screen and (max-width: 768px) {
    padding-bottom: 350px;
  }
}

.neumorphism,
.navbar-toggler,
.btn {
  //border-radius: 20px;
  background: linear-gradient(145deg, #d9e3e5, #ffffff);
  box-shadow:  10px 10px 20px #a9b0b2,
  -10px -10px 20px #ffffff;
  color: #42403d !important;
  transition: all .7s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    //border-radius: 20px;
    background: linear-gradient(145deg, #ffffff, #d9e3e5);
    box-shadow:  10px 10px 20px #a9b0b2,
    -10px -10px 20px #ffffff;
    color: #42403d !important;
    transition: all .7s ease-in-out;
  }
}

.navbar {
  //border-radius: 50px;
  //background: linear-gradient(0deg, #d9e3e5, #ffffff);
  box-shadow:  0 0 20px #a9b0b2,
  0 0 20px #ffffff;

  .navbar-brand {
    max-width: 70%;
    margin-top: 8px;
  }

  .flag {
    .img-wrappa {
      width: 30px;
      display: inline-block;

      img {
        height: 16px;
        width: 30px;
      }
    }
  }
}

.index__1 {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: #370082;
  background: url('../images/illustrations/lava-bg.png');
}

.sertificats_wrappa {
  .card {
    border-radius: 20px;

    .card-img-top {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .card-title {
      font-size: 1.2rem;
      text-decoration: none !important;
    }
  }
}

.footer {
  padding-top: 20px;
  color: #f0f0f0;
  background-color: #000000;

  .footer_logo {
    max-width: 50px;
  }

  @media only screen and (max-width: 768px) {
    height: auto !important;
    min-height: auto !important;
  }

  &:hover {
    .footer_logo {
      animation: rotate-scale-down 1s linear both;
    }
  }
}



