html {
  position: relative;
  min-height: 100%;
  overflow-y: scroll;
}
body {
  padding-bottom: 400px;
  margin-bottom: 160px; /* Margin bottom by footer height */
  min-height: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px; /* Set the fixed height of the footer here */
  //line-height: 160px; /* Vertically center the text there */
  //background-color: rgba(108, 99, 255, 0.35);
}

@keyframes rotate-scale-down {
  0% {
    transform: scale(1) rotateZ(0);
  }
  50% {
    transform: scale(0.5) rotateZ(180deg);
  }
  100% {
    transform: scale(1) rotateZ(360deg);
  }
}
